<template>
  <div>
    <div class="rn-contact-area rn-section-gap idcard-page">
      <v-card class="container px-6 elevation-24">
        <v-card-title class="pl-0 mb-4">
          <div class="d-flex flex-column justify-start">
            <span class="main-title">Welcome back</span>
            <span class="sub-title"
              >Login to your
              <span style="font-weight: 600">Siberian<br />account</span> on
              below</span
            >
          </div>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-text-field
            dense
            outlined
            v-model="id"
            label="Email"
            :rules="rules"
            v-on:click="messagemsg = ''"
            @keyup.enter="validate"
          />
          <v-text-field
            dense
            v-model="password"
            label="Password"
            class="passwordstyle"
            required
            outlined
            :rules="rulespassword"
            :type="typetext"
            :append-icon="icontext"
            @mousedown="(e) => handleappend(e)"
            @mouseup="(e) => handleappend(e)"
            :error-messages="messagemsg.length > 0 ? messagemsg : ''"
            v-on:click="messagemsg = ''"
            @keyup.enter="validate"
          />
          <!-- <v-text-field
              filled
              outlined
              dense
              type="password"
              v-model="formData.password"
              id="password" label="Password"
            /> -->
          <v-btn
            block
            large
            v-on:click="validate"
            color="primary"
            :disabled="id == '' || password == '' ? true : false"
            :loading="loadingLogin"
            >Enter</v-btn
          >
          <v-dialog
              v-model="dialog"
              hide-overlay
              persistent
            >
              <v-card
                color="secondary"
                class="ma-auto card-login"
                width="350px"
                dark
              >
                <v-card-title class="msg-title pa-3">
                  {{dialogMsg}}
                </v-card-title>
                <v-card-actions v-if="loginError">
                  <v-btn
                      color="error"
                      outlined
                      @click="dialog = false; loginError=false"
                  >
                      Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import PenomoranSales from "../components/menu-no-surat/PenomoranSales.vue";
import PenomoranProject from "../components/menu-no-surat/PenomoranProject.vue";
import SuratKeluarInternal from "../components/menu-no-surat/SuratKeluarInternal.vue";
import _ from "lodash";
import baseUrl from "../about.js";
const pbkdf2 = require("pbkdf2");

import { employeeData } from "../assets/plugin/datacrew.js";
import { fetchMethod } from "../plugins/restAPI";

export default {
  components: {
    PenomoranProject,
    PenomoranSales,
    SuratKeluarInternal,
  },
  data: () => ({
    // urlMain: "https://inplatform.sibernetik.co.id/testing/",
    baseUrl: baseUrl,
    message: false,
    loginError: false,
    // loading: true,
    id: "",
    username: "",
    password: "",
    usernameregister: "",
    passwordregister: "",
    passwordregisterreenter: "",
    usernameregisterright: false,
    passwordregisterreenterright: false,
    rules: [(value) => !!value || "Required"],
    rulespassword: [(value) => !!value || "Required"],
    message: false,
    messagemsg: "",
    dialog: false,
    rememberme: "",
    icontext: "mdi-eye",
    typetext: "password",
    dialogMsg: "",
    loadingLogin: false,
  }),
  methods: {
    validate() {
      let vm = this;
      vm.dialog = true;
      vm.loadingLogin = true;
      // vm.dialogMsg = "Logging In...";
      let userID = vm.id;
      let userPass = pbkdf2
        .pbkdf2Sync(vm.password, userID, 1, 32, "sha512")
        .toString("hex");
      console.log(userID);
      fetchMethod(vm.baseUrl + "/staff-profile/" + userID + ".json").then(
        (result) => {
          if (result) {
            console.log(result);
            console.log(userPass);
            if (userPass == result.password) {
              console.log("maduk");
              let loginInfo = {
                // userID: userID,
                ...result,
              };
              // vm.loading = false
              vm.dialogMsg = "Login Success!";
              vm.loadingLogin = false;
              setTimeout(() => {
                sessionStorage.setItem("loginInfo", JSON.stringify(loginInfo));
                sessionStorage.setItem("auth", "true");
                sessionStorage.setItem("loggedinas", this.username);
                vm.dialog = false;
                this.$router.push(`/generate-number/${userID}`);
              }, 1500);
            } else {
              vm.loginError = true;
              // vm.loading=false
              vm.loadingLogin = false;
              setTimeout(() => (this.loading = false), 4000);
              vm.dialogMsg =
                "User or password is incorrect, please check and try again...";
            }
          } else if (!result) {
            vm.loginError = true;
            vm.loadingLogin = false;
            vm.dialogMsg = "User not found, please check and try again... ";
          } else {
            vm.loginError = true;
            vm.loadingLogin = false;
            vm.dialogMsg = "Connection to Server is lost, Try again later...";
            // vm.messagemsg = "email or password is invalid";
          }
        }
      );
    },
    handleappend(e) {
      if (this.typetext == "password") {
        this.typetext = "text";
        this.icontext = "mdi-eye-off";
      } else if (this.typetext == "text") {
        this.typetext = "password";
        this.icontext = "mdi-eye";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.idcard-page {
  background-color: #e4e4e1;
  background: radial-gradient(
      at top center,
      rgba(255, 255, 255, 0.03) 0%,
      rgba(0, 0, 0, 0.03) 100%
    ),
    linear-gradient(
      to top,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(143, 152, 157, 0.6) 100%
    ),
    url("../assets/images/bg/9150533.jpg") center/cover;
  background-blend-mode: normal, multiply;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.container {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px !important;
  max-width: 380px;
  height: 400px;
  letter-spacing: 0.3px;
}
@media screen and (max-width: 767px) {
  .container {
    width: 95%;
  }
}
.card-login {
    position: absolute;
    left: 50.5%; top: 30%;
    transform: translateX(-50%);
}
.guidance-style {
  border-radius: 0px 10px 10px 0px !important;
  background-color: #152939 !important;
  width: 100%;
  height: 100%;
}
.main-title {
  font-size: 24px !important;
  color: #dd8732;
  line-height: 42px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif !important;
}
.sub-title {
  font-size: 18px !important;
  color: #4e4e4e;
  line-height: 20px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif !important;
}
.msg-title {
  font-size: 18px !important;
  color: #f5f5f5;
  font-weight: 300;
  font-family: "Noto Sans", sans-serif !important;
}
.passwordstyle {
  font: small-caption;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
</style>